<template>
  <NbModal
    id="modalUser"
    class="modalUser"
    modalConfig="modal-dialog-centered"
    width="800px"
  >
    <template #header>
      <h4 class="heading-4">
        {{ isEditMode ? $t("usersPage.editUser") : $t("usersPage.addUser") }}
      </h4>
    </template>
    <template #body>
      <form>
        <NbTextInput
          v-model="form.name"
          required
          :name="$t('name')"
          id="name"
          :error="errors['name']"
        />
        <div class="group">
          <NbTextInput
            v-model="form.phone"
            :name="$t('phone')"
            :masks="['(##) #####-####']"
            id="phone"
            :error="errors['phone']"
          />
          <NbTextInput
            v-model="form.whatsapp_number"
            :name="$t('whatsapp')"
            :masks="['(##) #####-####']"
            id="whatsapp_number"
            :error="errors['whatsapp_number']"
          />
        </div>

        <NbTextInput
          v-model="form.email"
          required
          :name="$t('email')"
          id="email"
          :disabled="isEditMode"
          :error="errors['email']"
        />
        <div>
          <NbSelectInput
            v-model="form.group_id"
            required
            :options="groupOptions"
            :name="$t('group')"
            id="group_id"
            :error="errors['group_id']"
            :helpText="$t('usersPage.groupTooltip')"
          />
        </div>
        <div v-if="userAuth.profile === profiles.NOBORDIST">
          <NbSelectInput
            v-model="form.profile"
            required
            :options="profileOptions"
            :name="$t('profile')"
            id="profile"
            :error="errors['profile']"
          />
        </div>
        <div
          v-if="
            userAuth.profile === profiles.NOBORDIST &&
            user.profile === profiles.SELLER
          "
        >
          <NbSelectInput
            v-model="form.profile"
            required
            :options="sellerOptions"
            :name="$t('profile')"
            id="profile"
            :error="errors['profile']"
          />
        </div>
        <div
          v-if="
            userAuth.profile === profiles.NOBORDIST &&
            user.profile === profiles.CARRIER
          "
        >
          <NbSelectInput
            v-model="form.profile"
            required
            :options="carrierOptions"
            :name="$t('profile')"
            id="profile"
            :error="errors['profile']"
          />
        </div>
        <NbSwitch
          v-model="form.is_blocked"
          :name="$t('blocked') + '?'"
          id="is_blocked"
          :helpText="$t('usersPage.blockedTooltip')"
        />
      </form>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" data-dismiss="modal">{{
          $t("cancel")
        }}</NbButton>
        <NbButton
          v-if="isEditMode"
          class="ml-2"
          @click="edit"
          :disabled="loading"
          >{{ $t("save") }}</NbButton
        >
        <NbButton v-else class="ml-2" @click="save" :disabled="loading">{{
          $t("save")
        }}</NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import UsersService from "@/services/UsersService";
import UserService from "@/services/UserService";
import { PROFILES } from "../../../../constants";
import SellerService from "../../../../services/SellerService";
import CarrierService from "../../../../services/CarrierService";

const usersService = new UsersService();
const userService = new UserService();
const sellerService = new SellerService();
const carrierService = new CarrierService();

export default {
  name: "ModalUser",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbSwitch,
    NbSelectInput,
  },
  data() {
    return {
      loading: false,
      userAuth: userService.getLoggedInUser(),
      profiles: PROFILES,
      carriers: [],
      sellers: [],
      errors: [],
      form: {
        name: "",
        email: "",
        phone: "",
        whatsapp_number: "",
        group_id: "",
        is_blocked: false,
        profile: "",
      },
    };
  },
  watch: {
    user(data) {
      if (data?.id) {
        this.errors = [];
        this.fillForm();
        return;
      }

      this.resetForm();
    },
  },
  computed: {
    isEditMode() {
      return !!this.user?.id;
    },
    groupOptions() {
      return this.groups.map(({ id, name }) => ({ text: name, value: id }));
    },
    profileOptions() {
      return Object.entries(PROFILES).map(([, value]) => ({
        text: value,
        value: value,
      }));
    },
    sellerOptions() {
      return this.sellers.map(({ id, name }) => ({ text: name, value: id }));
    },
    carrierOptions() {
      return this.carriers.map(({ id, name }) => ({ text: name, value: id }));
    },
  },
  methods: {
    fillForm() {
      this.form = {
        name: this.user?.name,
        email: this.user?.email,
        phone: this.user?.phone,
        whatsapp_number: this.user?.whatsapp_number,
        group_id: this.user?.group_id,
        is_blocked: this.user?.is_blocked,
        profile: this.user?.profile,
      };

      if (this.userAuth.profile === this.profiles.NOBORDIST) {
        this.form["seller_id"] = this.user?.seller_id;
        this.form["carrier_id"] = this.user?.carrier_id;
      }
    },
    resetForm() {
      this.errors = [];
      this.form = {
        name: "",
        email: "",
        phone: "",
        whatsapp_number: "",
        group_id: "",
        is_blocked: false,
        profile: "",
      };
    },
    async save() {
      try {
        this.loading = true;
        const profile = [PROFILES.SELLER, PROFILES.CARRIER].includes(
          this.userAuth.profile
        )
          ? this.userAuth.profile
          : this.form.profile;
        await usersService.setUser({
          ...this.form,
          profile,
        });
        this.$helpers.closeModal("modalUser");
        this.$emit("success");
      } catch (error) {
        const errorMessages = error.response?.data?.messages || [];
        this.errors = errorMessages[0];
        if (errorMessages.length && typeof errorMessages[0] === "string") {
          this.$helpers.toast(errorMessages, "warning");
          return;
        }
        this.$helpers.toast(this.$t("errorMessages.tryAgain"), "warning");
      } finally {
        this.loading = false;
      }
    },
    async edit() {
      try {
        this.loading = true;
        await usersService.updateUser(this.user?.id, this.form);
        this.$helpers.closeModal("modalUser");
        this.$emit("success");
      } catch (error) {
        const errorMessages = error.response?.data?.messages || [];
        this.errors = errorMessages[0];
        if (errorMessages.length && typeof errorMessages[0] === "string") {
          this.$helpers.toast(errorMessages, "warning");
          return;
        }
        this.$helpers.toast(this.$t("errorMessages.tryAgain"), "warning");
      } finally {
        this.loading = false;
      }
    },
    loadSellers() {
      sellerService.getSellers().then((response) => {
        this.sellers = response.data.data.elements;
        this.form.seller_id = null;
      });
    },
    loadCarriers() {
      carrierService.getCarriers().then((response) => {
        this.carriers = response.data.data.elements;
        this.form.carrier_id = null;
      });
    },
  },
  created() {
    if (this.userAuth.profile === PROFILES.NOBORDIST) {
      this.loadSellers();
      this.loadCarriers();
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
.modalUser {
  .group {
    display: grid;
    gap: 1rem;
  }
  form > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  @media screen and (min-width: 650px) {
    .group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
