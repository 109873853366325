<template>
  <base-template>
    <LoadingPage v-if="loaded" />
    <div v-else class="row justify-content-center">
      <div class="col-12">
        <h5 class="font-weight-light text-uppercase mb-4">
          {{ $t("userProfileEditPage.title") }}
        </h5>
        <div class="card bg-light border-white rounded py-4">
          <form v-on:submit.prevent="updateProfile">
            <div class="row mx-5 my-1">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{ $t("name") }}</label>
                  <input
                    type="text"
                    v-model="user.name"
                    id="name"
                    class="form-control"
                    required
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="phone">Phone Number</label>
                  <input
                    type="text"
                    v-model="user.phone"
                    id="phone"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row mx-5 my-1">
              <div class="col">
                <div class="form-group">
                  <label for="company_name">Company</label>
                  <input
                    v-model="user.company_name"
                    id="company_name"
                    class="form-control"
                    readonly="true"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="whatsapp_number">{{ $t("whatsapp") }}</label>
                  <input
                    type="text"
                    v-model="user.whatsapp_number"
                    id="whatsapp_number"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row mx-5 my-1">
              <div class="col">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    v-model="user.email"
                    id="email"
                    class="form-control"
                    readonly="true"
                  />
                </div>
              </div>
              <div class="col"></div>
            </div>

            <div class="row mx-5 mt-3 d-flex justify-content-center">
              <button type="submit" class="mt-2 button-secondary float-center">
                {{ $t("save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </base-template>
</template>

<script>
import BaseTemplate from "../BaseTemplate";
import UserService from "@/services/UserService";
import UsersService from "@/services/UsersService";
import NProgress from "nprogress";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const userService = new UserService();
const usersService = new UsersService();

export default {
  name: "UserProfileEdit",
  components: { BaseTemplate, LoadingPage },
  data: function () {
    return {
      user: userService.getLoggedInUser(),
      loaded: true,
    };
  },
  beforeMount() {
    setTimeout(() => (this.loaded = false), 1000);
  },
  methods: {
    updateProfile() {
      usersService
        .updateProfile(this.user)
        .then(() => {
          userService.setLoggedInUser(JSON.stringify(this.user));

          this.$helpers.toast(
            this.$t("userProfileEditPage.updatedText"),
            "success",
            3000
          );
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();

          window.scrollTo(0, 0);
        });
    },
  },
};
</script>
