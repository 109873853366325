<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('userProfilePage.title')"
      :subTitle="$t('userProfilePage.firstText')"
    />

    <section class="w-100 pl-3">
      <NbCardForm
        class="mb-2"
        :width="width"
        actionBtn="Update User"
        @actionCliked="updateProfile()"
        :title="$t('loginPage.userData')"
        :subTitle="$t('userProfilePage.checkAndEditUser')"
        :errors="errors_user"
        :formInputs="userInputs"
        v-model="user"
      />
      <NbCardForm
        class="mb-4"
        :width="width"
        actionBtn="Update Login"
        modalTarget="modalCurrentPass"
        :title="$t('userProfilePage.loginInfo')"
        :subTitle="$t('userProfilePage.checkAndEditLogin')"
        :errors="errors_user"
        :formInputs="loginInputs"
        :disabled="isInvalidLoginInputs"
        v-model="user"
      />
      <!-- @formChange="updateUser($event)" -->
    </section>

    <NbFooter :text="$t('components.footer.configureYourAcc')" link="#" />

    <!-- modals -->
    <NbModal
      id="modalCurrentPass"
      modalConfig="modal-dialog-centered"
      :preventClose="true"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("confirmOldPassword") }}
        </div>
      </template>
      <template v-slot:body>
        <p>To perform this action, please type your current password</p>
        <NbPassInput
          required
          id="reset-email"
          name="Old Password"
          class="mb-2"
          autocomplete="off"
          :placeholder="$t('confirmOldPassword')"
          :error="[pwErrorMsg]"
          v-model="password"
        />
      </template>
      <template v-slot:footer>
        <NbButton class="float-right mr-3" @click="updateEmailPassword()">{{
          $t("save")
        }}</NbButton>
        <NbButton
          variant="secondary"
          class="float-right mr-2"
          @click="closeModal('modalCurrentPass-close')"
          >{{ $t("cancel") }}</NbButton
        >
      </template>
    </NbModal>
    <!-- /modals -->
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import UsersService from "@/services/UsersService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCardForm from "@/components/forms/NbCardForm.vue";
import NProgress from "nprogress";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";

const userService = new UserService();
const usersService = new UsersService();

export default {
  name: "UserProfile",
  components: {
    LoadingPage,
    NbPageTitle,
    NbCardForm,
    NbFooter,
    NbModal,
    NbPassInput,
    NbButton,
  },
  data: function () {
    return {
      oldEmail: null,
      password: null,
      user: {
        newPassword: null,
        newPassword_confirm: null,
        email_confirm: null,
      },
      loaded: true,
      newPassword: null,
      pwErrorMsg: "",
      pwErrorMsgConfirm: "",
      emailErrorMsg: "",
      errors_user: {},
      width: 614,
      isInvalidLoginInputs: true,
    };
  },
  methods: {
    clearErrors() {
      this.pwErrorMsg = "";
      this.pwErrorMsgConfirm = "";
      this.emailErrorMsg = "";
    },
    updateEmailPassword() {
      this.clearErrors();
      let dataToSend = { password: this.password };
      if (this.oldEmail !== this.user.email) {
        dataToSend["new_email"] = this.user.email;
      }
      if (this.user.newPassword.length > 0) {
        dataToSend["new_password"] = this.user.newPassword;
      }
      usersService
        .updateEmailPassword(dataToSend)
        .then((response) => {
          userService.setLoggedInUser(JSON.stringify(this.user));
          this.$helpers.toast(
            response.data.messages[0] || "Success",
            "success"
          );

          this.closeModal("modalCurrentPass-close");
        })
        .catch((error) => {
          this.loading = false;
          this.pwErrorMsg =
            error.response.data.messages[0] || "Something unexcepted happened.";
          NProgress.done();
        });
    },
    updateProfile() {
      usersService
        .updateProfile(this.user)
        .then(() => {
          userService.setLoggedInUser(JSON.stringify(this.user));

          this.$helpers.toast(
            this.$t("userProfileEditPage.updatedText"),
            "success",
            3000
          );
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    validateEmailPass() {
      if (
        this.pwErrorMsg.length < 1 &&
        this.emailErrorMsg < 1 &&
        this.pwErrorMsgConfirm.length < 1 &&
        (this.user.newPassword.length > 0 || this.user.email_confirm.length > 0)
      ) {
        this.isInvalidLoginInputs = false;
        return;
      }
      this.isInvalidLoginInputs = true;
    },
    resetUserCredentials() {
      this.user.newPassword = "";
      this.user.newPassword_confirm = "";
      this.user.email_confirm = "";
      this.password = "";
      this.isInvalidLoginInputs = true;
    },
    closeModal(modalId) {
      document.getElementById(modalId).click();
      this.resetUserCredentials();
      setTimeout(() => {
        this.clearErrors();
      }, 200);
    },
  },
  computed: {
    userInputs() {
      return [
        {
          required: true,
          id: "newUser_name",
          name: this.$t("fullName"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("fullName"),
          key: "name",
        },
        {
          required: true,
          disabled: true,
          id: "newUser_company",
          name: this.$t("company"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("company"),
          key: "company_name",
        },
        {
          required: false,
          id: "newUser_phone",
          name: this.$t("phone"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: "00000000000",
          key: "phone",
        },
        {
          required: true,
          id: "newUser_whatsapp",
          name: this.$t("whatsapp"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: "00000000000",
          key: "whatsapp_number",
        },
      ];
    },
    loginInputs() {
      return [
        {
          required: true,
          id: "newSeller_email",
          name: this.$t("email"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("placeholderEmail"),
          key: "email",
        },
        {
          required: true,
          id: "newSeller_email_confirm",
          name: this.$t("confirmEmail"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("placeholderEmail"),
          key: "email_confirm",
          autocomplete: "off",
          error: [this.emailErrorMsg],
        },
        {
          required: true,
          type: "password",
          id: "newSeller_password",
          name: this.$t("passwordNewPage.newPassword"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("password"),
          key: "newPassword",
          autocomplete: "off",
          error: [this.pwErrorMsg],
        },
        {
          required: true,
          type: "password",
          id: "newSeller_password_confirm",
          name: this.$t("confirmPassword"),
          inputGroupClass: "mb-3 w-48-5",
          placeholder: this.$t("password"),
          key: "newPassword_confirm",
          autocomplete: "off",
          error: [this.pwErrorMsgConfirm],
        },
      ];
    },
  },
  watch: {
    "user.email": function () {
      if (
        this.user.email &&
        (this.user.email.length < 8 || !this.user.email.match(/.*@.*\..+/))
      ) {
        this.emailErrorMsg = "Please check your email format";
      } else if (
        this.user.email.length > 0 &&
        this.user.email != this.user.email_confirm
      ) {
        this.emailErrorMsg = "Please confirm your email";
      } else {
        this.emailErrorMsg = "";
      }
    },
    "user.email_confirm": function () {
      if (
        this.user.email &&
        (this.user.email.length < 8 || !this.user.email.match(/.*@.*\..+/))
      ) {
        this.emailErrorMsg = "Please check your email format";
      } else if (
        this.user.email_confirm.length > 0 &&
        this.user.email.length > 0 &&
        this.user.email != this.user.email_confirm
      ) {
        this.emailErrorMsg = "Please confirm your email";
      } else {
        this.emailErrorMsg = "";
      }
    },
    "user.newPassword": function () {
      let pwErrors = [];
      let pwErrorsConfirm = [];
      if (
        (this.user.newPassword.match(/\s/) ||
          this.user.newPassword.length < 11 ||
          !this.user.newPassword.match(/[a-z]/) ||
          !this.user.newPassword.match(/[A-Z]/) ||
          !this.user.newPassword.match(/\d/) ||
          !this.user.newPassword.match(/\W/)) &&
        this.user.newPassword.length > 0
      ) {
        if (this.user.newPassword.match(/\s/)) {
          pwErrors.push("should not contain white space");
        }
        if (this.user.newPassword.length < 11) {
          pwErrors.push("at least 11 characters");
        }
        if (!this.user.newPassword.match(/[a-z]/)) {
          pwErrors.push("at least 1 lowercase letter");
        }
        if (!this.user.newPassword.match(/[A-Z]/)) {
          pwErrors.push("at least 1 uppercase letter");
        }
        if (!this.user.newPassword.match(/\d/)) {
          pwErrors.push("at least 1 number");
        }
        if (!this.user.newPassword.match(/\W/)) {
          pwErrors.push("at least 1 special character");
        }
      }
      if (
        this.user.newPassword !== this.user.newPassword_confirm &&
        this.user.newPassword.length > 0
      ) {
        pwErrorsConfirm.push("Please confirm your password");
      }
      this.pwErrorMsgConfirm = pwErrorsConfirm.join(", ");
      this.pwErrorMsg = pwErrors.join(", ");
    },
    "user.newPassword_confirm": function () {
      let pwErrorsConfirm = [];
      if (this.user.newPassword !== this.user.newPassword_confirm) {
        pwErrorsConfirm.push("Please confirm your password");
      }
      this.pwErrorMsgConfirm = pwErrorsConfirm.join(", ");
    },
    pwErrorMsg() {
      this.validateEmailPass();
    },
    pwErrorMsgConfirm() {
      this.validateEmailPass();
    },
    emailErrorMsg() {
      this.validateEmailPass();
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.loaded = false;
      const currentUser = userService.getLoggedInUser();
      this.user = Object.assign(currentUser, this.user);
      this.oldEmail = JSON.parse(JSON.stringify(this.user.email));
      this.resetUserCredentials();
      setTimeout(() => {
        this.clearErrors();
      }, 200);
    }, 1000);
  },
};
</script>
