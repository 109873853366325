<template>
  <div class="users">
    <NbTablev2
      :ref="`${reference}Table`"
      :tableOf="reference"
      :namespace="namespace"
      :fields="fields"
      :allFields="tableFields"
      @reloadFields="fields = $event"
      :filterOptions="filters"
    >
      <template #cell(group_name)="data">
        <span class="group">{{ data.item.group_name }}</span>
      </template>

      <template #cell(is_blocked)="data">
        <div class="d-flex align-items-center">
          <div :class="['blocked', { isBlocked: data.item.is_blocked }]"></div>
          <span class="ml-2 body-3">{{
            !data.item.is_blocked ? $t("yes") : $t("no")
          }}</span>
        </div>
      </template>

      <template #cell(actions)="data">
        <NbButton variant="tertiary" icon="edit" @click="onEdit(data.item)">{{
          $t("edit")
        }}</NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          @click="onDelete(data.item)"
          >{{ $t("delete") }}</NbButton
        >
      </template>
    </NbTablev2>
  </div>
</template>

<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
export default {
  name: "UsersTable",
  props: {
    groups: {
      type: Array,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
  },
  components: {
    NbTablev2,
    NbButton,
  },
  data() {
    return {
      fields: [],
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "company_name", label: this.$t("company") },
        { key: "group_name", label: this.$t("group") },
        { key: "email", label: this.$t("email") },
        { key: "is_blocked", label: this.$t("usersPage.allowAccess") },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    groupOptions() {
      return this.groups.map(({ id, name }) => ({ text: name, value: id }));
    },
    filters() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "email", label: this.$t("email") },
        {
          key: "group_id",
          label: this.$t("group"),
          type: "select",
          options: this.groupOptions,
        },
        { key: "is_blocked", label: this.$t("blocked"), type: "boolean" },
      ];
    },
  },
  methods: {
    getData() {
      const ref = `${this.reference}Table`;
      this.$refs[ref]?.getData();
    },
    onDelete(user) {
      this.$emit("delete", user);
    },
    onEdit(user) {
      this.$emit("edit", user);
    },
  },
};
</script>

<style lang="scss" scoped>
.users {
  .group {
    background-color: var(--gray-05);
    padding: 4px 8px;
    border-radius: 4px;
  }
  .blocked {
    height: 1rem;
    width: 1rem;
    background-color: var(--gray-05);
    border-radius: 50%;
    border: 1px solid transparent;
    outline: 1px solid var(--gray-60);
    display: flex;
    &:not(.isBlocked) {
      background-color: var(--black);
      border-color: var(--gray-05);
    }
    & + span {
      font-weight: 500;
    }
  }
}
</style>
