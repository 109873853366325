<template>
  <div v-show="active">
    <UsersTable
      v-if="keepAlive"
      ref="allUsers"
      reference="allUsersTable"
      namespace="users"
      :groups="groups"
      @delete="$emit('delete', $event)"
      @edit="$emit('edit', $event)"
    />
  </div>
</template>

<script>
import UsersTable from "./UsersTable.vue";

export default {
  name: "UsersBlocked",
  props: {
    groups: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    UsersTable,
  },
  data() {
    return {
      keepAlive: false,
    };
  },
  watch: {
    active(newValue) {
      if (newValue && !this.keepAlive) {
        this.keepAlive = true;
      }
    },
  },
  methods: {
    loadData() {
      this.$refs.allUsers?.getData();
    },
  },
};
</script>
