<template>
  <div v-show="active">
    <UsersTable
      v-if="keepAlive"
      ref="sellerAdm"
      reference="sellerAdmTable"
      :namespace="`users?group_id=${groupSellerAdmin}`"
      :groups="groups"
      @delete="$emit('delete', $event)"
      @edit="$emit('edit', $event)"
    />
  </div>
</template>

<script>
import UsersTable from "./UsersTable.vue";

export default {
  name: "SellerAdm",
  props: {
    groups: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    UsersTable,
  },
  data() {
    return {
      keepAlive: false,
    };
  },
  computed: {
    groupSellerAdmin() {
      return this.groups.find((group) => group.name === "Seller-Admin")?.id;
    },
  },
  watch: {
    active(newValue) {
      if (newValue && !this.keepAlive) {
        this.keepAlive = true;
      }
    },
  },
  methods: {
    loadData() {
      this.$refs.sellerAdm?.getData();
    },
  },
};
</script>
