<template>
  <LoadingPage v-if="loading" />
  <div v-else>
    <div class="d-flex justify-content-between">
      <div class="page-info">
        <h4 class="heading-4">{{ $t("usersPage.title") }}</h4>
        <p class="body-4">{{ $t("usersPage.subtitle") }}</p>
      </div>

      <div>
        <h3 class="heading-3">{{ $t("usersPage.newUser") }}</h3>
        <NbButton icon="plus" @click="showCreateUserModal">{{
          $t("usersPage.addUser")
        }}</NbButton>
      </div>
    </div>

    <NbTabs :tabs="tabsOptions" class="mb-4" @switchTab="switchTab" />

    <AllUsers
      :active="currentTab === tabs.all"
      ref="allUsers"
      :groups="groups"
      @delete="triggerDeleteModal"
      @edit="triggerEditModal"
    />

    <SellerAdm
      :active="currentTab === tabs.sellerAdmin"
      ref="sellerAdm"
      :groups="groups"
      @delete="triggerDeleteModal"
      @edit="triggerEditModal"
    />

    <SellerOperation
      :active="currentTab === tabs.sellerOperation"
      ref="sellerOperation"
      :groups="groups"
      @delete="triggerDeleteModal"
      @edit="triggerEditModal"
    />

    <SellerViewer
      :active="currentTab === tabs.sellerViewer"
      ref="sellerViewer"
      :groups="groups"
      @delete="triggerDeleteModal"
      @edit="triggerEditModal"
    />

    <UsersBlocked
      v-show="currentTab === tabs.usersBlocked"
      :active="currentTab === tabs.usersBlocked"
      ref="usersBlocked"
      :groups="groups"
      @delete="triggerDeleteModal"
      @edit="triggerEditModal"
    />

    <ModalUser :user.sync="selectedUser" @success="refresh" :groups="groups" />

    <NbModal id="modalDeleteUser">
      <template #header>
        <span>
          {{ $t("confirmDelete", { val: selectedUser?.name }) }}
        </span>
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <NbButton data-dismiss="modal" variant="secondary">{{
            $t("cancel")
          }}</NbButton>
          <NbButton
            class="ml-2"
            @click="deleteUser()"
            :disabled="loadingDelete"
            >{{ $t("confirm") }}</NbButton
          >
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbModal from "@/components/modal/NbModal.vue";

import GroupService from "@/services/GroupService";
import AllUsers from "./components/Users/AllUsers.vue";
import SellerAdm from "./components/Users/SellerAdm.vue";
import SellerOperation from "./components/Users/SellerOperation.vue";
import SellerViewer from "./components/Users/SellerViewer.vue";
import UsersBlocked from "./components/Users/UsersBlocked.vue";
import UsersService from "@/services/UsersService";
import ModalUser from "./components/Users/ModalUser.vue";

const groupService = new GroupService();
const usersService = new UsersService();

export default {
  components: {
    NbButton,
    NbTabs,
    LoadingPage,
    AllUsers,
    SellerAdm,
    SellerOperation,
    SellerViewer,
    UsersBlocked,
    NbModal,
    ModalUser,
  },
  data() {
    return {
      loading: true,
      loadingDelete: false,
      currentTab: "",
      fields: [],
      tabs: {
        all: "AllUsers",
        sellerAdmin: "SellerAdm",
        sellerOperation: "SellerOperation",
        sellerViewer: "SellerViewer",
        usersBlocked: "UsersBlocked",
      },
      groups: [],
      selectedUser: {},
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.tabs.all,
          label: this.$t("usersPage.all"),
          current: this.currentTab,
        },
        {
          id: this.tabs.sellerAdmin,
          label: this.$t("usersPage.sellerAdmin"),
          current: this.currentTab,
        },
        {
          id: this.tabs.sellerOperation,
          label: this.$t("usersPage.sellerOperation"),
          current: this.currentTab,
        },
        {
          id: this.tabs.sellerViewer,
          label: this.$t("usersPage.sellerViewer"),
          current: this.currentTab,
        },
        {
          id: this.tabs.usersBlocked,
          label: this.$t("usersPage.blocked"),
          current: this.currentTab,
        },
      ];
    },
    tableFields() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "company_name", label: this.$t("company") },
        { key: "group_id", label: this.$t("groupConfigurationPage.group") },
        { key: "email", label: this.$t("email") },
        { key: "is_blocked", label: `${this.$t("blocked")}?` },
      ];
    },
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },
    async loadGroups() {
      try {
        const { data } = await groupService.getGroups();
        this.groups = data.data;
      } finally {
        this.loading = false;
      }
    },
    triggerDeleteModal(user) {
      this.selectedUser = user;
      this.$helpers.openModal("modalDeleteUser");
    },
    triggerEditModal(user) {
      this.selectedUser = user;
      this.$helpers.openModal("modalUser");
    },
    async deleteUser() {
      try {
        this.loadingDelete = true;
        await usersService.deleteUser(this.selectedUser?.id);
        this.$helpers.closeModal("modalDeleteUser");
        this.refresh();
      } catch (error) {
        const errors = error?.response?.data?.messages || [];
        if (errors?.length) {
          this.$helpers.toast(errors[0], "danger");
        }
      } finally {
        this.loadingDelete = false;
      }
    },
    showCreateUserModal() {
      this.selectedUser = {};
      this.$helpers.openModal("modalUser");
    },
    refresh() {
      this.$refs.allUsers?.loadData();
      this.$refs.sellerAdm?.loadData();
      this.$refs.sellerOperation?.loadData();
      this.$refs.sellerViewer?.loadData();
      this.$refs.usersBlocked?.loadData();
    },
  },
  async created() {
    await this.loadGroups();
    this.currentTab = this.tabs.all;
  },
};
</script>

<style lang="scss" scoped></style>
