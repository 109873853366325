<template>
  <base-template>
    <LoadingPage v-if="loaded" />
    <div v-else>
      <div class="d-flex flex-col flex-md-row justify-content-md-between mb-4">
        <div class="ml-3">
          <h4 class="heading-4">
            {{ $t("userProfileBalancePage.title") }}
          </h4>
          <h4
            class="heading-4 m-0"
            :data-balance="balance >= 0 ? 'credit' : 'debit'"
          >
            {{ balance | currency }}
          </h4>
        </div>
        <NbButton @click.prevent="downloadFiances()">
          {{ $t("userProfileBalancePage.downloadReport") }}
        </NbButton>
      </div>

      <NbTabs :tabs="tabsOptions" />
      <NbTablev2
        class="mt-4"
        tableOf="balance"
        hasParentItens
        ref="balanceRef"
        hiddeTableOptions
        height="fit-content"
        :allFields="allFields"
        :fields="tableFields"
        @reloadFields="tableFields = $event"
        :start-items="accountEvents"
      >
        <template #cell(value)="data">
          <span :data-balance="data.item.value >= 0 ? 'credit' : 'debit'">
            {{ data.item.value | currency }}
          </span>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | datetime }}
        </template>
      </NbTablev2>

      <div class="d-flex justify-content-end">
        <b-pagination
          class="pb-0 mb-0 nb-pagination"
          aria-controls="my-table"
          size="sm"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-text="|<"
          prev-text="<"
          next-text=">"
          last-text=">|"
        />
      </div>
    </div>
  </base-template>
</template>

<script>
import BaseTemplate from "../BaseTemplate";
import SellerAccountService from "@/services/SellerAccountService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
const sellerAccountService = new SellerAccountService();
import { BPagination } from "bootstrap-vue";
import DownloadsService from "@/services/DownloadsService";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import moment from "moment";
import SellerService from "../../services/SellerService";

const sellerService = new SellerService();

const downloadsService = new DownloadsService();
export default {
  name: "ViewSellers",
  components: {
    BaseTemplate,
    LoadingPage,
    BPagination,
    NbTablev2,
    NbButton,
    NbTabs,
  },
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formatter?.format(value);
    },
    datetime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      tableFields: [],
      fields: [
        { key: "name", label: "Full name", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "is_blocked", label: "Status" },
      ],
      totalRows: 1,
      accountEvents: [],
      perPage: 15,
      filter: null,
      filterOn: ["name"],
      contractId: "",
      contractIndex: "",
      sellerId: "",
      currentPage: 1,
      loaded: false,
      balance: null,
      currentTab: "statement",
    };
  },
  async beforeMount() {
    const { data } = await sellerService.getCurrentSeller();
    this.sellerId = data.data.id;
    this.getBalance();
    this.loadAccountEvents();
  },
  watch: {
    currentPage() {
      this.loadAccountEvents();
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "statement",
          label: this.$t("userProfileBalancePage.statement"),
          current: this.currentTab,
        },
      ];
    },
    allFields() {
      return [
        {
          key: "description",
          label: this.$t("userProfileBalancePage.fields.description"),
        },
        {
          key: "value",
          label: this.$t("userProfileBalancePage.fields.value"),
        },
        {
          key: "created_at",
          label: this.$t("date"),
        },
      ];
    },
  },
  methods: {
    downloadFiances() {
      this.initiateFileCreation({
        data_type: "seller_account_events",
        reference_ids: this.selected,
      });
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    getBalance() {
      sellerAccountService.getBalance(this.sellerId).then((response) => {
        this.balance = response.data.balance;
      });
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear()
        ) +
        "  " +
        this.addZero(data.getHours()) +
        ":" +
        this.addZero(data.getMinutes()) +
        ":" +
        this.addZero(data.getSeconds());
      return formatedDate;
    },
    addHTTPS(link) {
      if (link.includes("http")) {
        return link;
      }
      return "https://" + link;
    },
    loadAccountEvents() {
      this.loaded = true;
      sellerAccountService
        .getEvents(this.sellerId, this.currentPage)
        .then((response) => {
          this.accountEvents = response.data.data.elements;
          this.totalRows = response.data.data.count;
        })
        .finally(() => {
          this.loaded = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  overflow-y: auto;
}

[data-balance="credit"] {
  color: var(--success);
}
[data-balance="debit"] {
  color: var(--danger);
}
</style>
